import Logo from "../Assets/logo.png"
import FacebookLogo from "../Assets/facebook-logo.png"
import YoutubeLogo from "../Assets/youtube-logo.png"
import InstagramLogo from "../Assets/instagram-logo.png"
import TiktokLogo from "../Assets/tiktok-logo.png"
import DancingGorillaGif from "../Assets/dancing-gorilla.gif"
import DancingGorillaMp4 from "../Assets/dancing-gorilla.mp4"
import { useState } from 'react';
import Popup from "../Components/Popup"

const Home = () => {
    const [buttonPopup, setButtonPopup] = useState(false);

    return (
        <div className="box">
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}></Popup>

            <div className="desktop-wrapper">
                <div className="desktop">
                    <div className="logo-box">
                        <div className="logo-wrapper">
                            <img className="logo" alt="Chug Chug Recovery" src={Logo} />
                        </div>
                    </div>
                    
                    <div className="main-text-box">
                        <img className="dancing-gorilla" src={DancingGorillaGif} />
                        {/* <video className="dancing-gorilla" width="950" height="720" loop muted autoPlay>
                            <source src={DancingGorillaMp4} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> */}
                

                        <div className="main-text-wrapper">
                            <h1 className="main-text">Stop feeling <br></br> like shit in the <br></br> morning.</h1>
                            <div className="sub-text-wrapper">
                                <h1 className="sub-text">Say goodbye to Hangovers :)</h1>
                            </div>
                        </div>
                    </div>

                    <div className="waitlist-box">
                        <button className="waitlist-button" onClick={() => setButtonPopup(true)}>Join the waitlist</button>
                    </div>

                    <div className="social-media-badges-box">
                        <div className="social-media-badges-wrapper">
                            <a className="tiktok-button" target="_blank" href="https://www.tiktok.com/@drinkchugchug">
                                <input type="image" className="tiktok-button" src={TiktokLogo}></input>
                            </a>
                
                            <a className="instagram-button" target="_blank" href="https://www.instagram.com/drink_chugchug/?hl=en">
                                <input type="image" className="instagram-button" src={InstagramLogo}></input>
                            </a>

                            <a className="youtube-button" target="_blank" href="https://www.youtube.com/@Drink_ChugChug">
                                <input type="image" className="youtube-button" src={YoutubeLogo}></input>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
