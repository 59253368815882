import React, { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from '../firebase.js';

import MailIcon from "../Assets/mail-icon.png"

const Popup = (props) => {
    const [email, setEmail] = useState("")
   
    const addEmail = async (e) => {
        e.preventDefault();
       
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            await setDoc(doc(db, "emails", email), {
                email: email,    
            })

            showSubscribedScreen();
        } else {
            document.getElementById("alertPopup").classList.toggle("show");
        }
    }

    const hideAlertPopup = () => {
        document.getElementById("alertPopup").classList.remove("show");
    }

    const showSubscribedScreen = () => {
        document.getElementById("mailIconWrapper").style.display = "none";
        document.getElementById("inputWrapper").style.display = "none";
        document.getElementById("popupTitleText").style.display = "none";
        document.getElementById("popupSubText").style.display = "none";
        document.getElementById("subscribedText").style.display = "block";
        document.getElementById("confetti").style.display = "block";
    }

    return (props.trigger) ? (
        <div className="popup-box">
            <div className="popup-background"></div>
            <div className="popup">
                <div className="popup-inner">
                    <div class="popup-inner confetti" id="confetti">
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                        <div class="confetti-piece"></div>
                    </div>

                    <button className="close-button" onClick={() => props.setTrigger(false)}>X</button>
                    { props.children }
                    <div className="mail-icon-wrapper" id="mailIconWrapper">
                        <img className="mail-icon" alt="Mailing" src={MailIcon} />
                    </div>
                    <div className="popup-wrapper">
                        <div className="popup-text-wrapper" id="popupTextWrapper">
                            <div className="popup-title-text" id="popupTitleText">
                                Follow Our Drop
                            </div>
                            <div className="subscribed-text" id="subscribedText">
                                Thanks for folllowing! <br></br> 🥂
                            </div>

                            <div className="popup-sub-text" id="popupSubText">
                                Be the first to know about our latest updates and exclusive promos.
                            </div>
                        </div>

                        <div id="inputWrapper">
                            <div className="email-wrapper">
                                <span className="alert-popup" id="alertPopup">Uh-Oh! That email appears to be invalid.</span>
                                <input type="text" className="email" placeholder="Email" onClick={hideAlertPopup} onChange={(e)=>setEmail(e.target.value)}></input>
                            </div>

                            <div className="subscribe-button-wrapper" >
                                <button className="subscribe-button" onClick={addEmail}>
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : "";
}

export default Popup;
